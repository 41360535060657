import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-aeslis-blue text-white p-4 mt-8">
      <div className="container mx-auto text-center">
        <p>© 2024 AESLIS. Tutti i diritti riservati.</p>
      </div>
    </footer>
  );
};

export default Footer;