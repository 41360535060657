import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import PostManagement from './components/PostManagement';
import LessonManagement from './components/LessonManagement';
import LessonList from './components/LessonList';

const PrivateRoute = ({ children, allowedRoles }) => {
  const userRole = localStorage.getItem('userRole');
  const isAuthenticated = !!localStorage.getItem('token');

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/users" element={
              <PrivateRoute allowedRoles={['super_admin']}>
                <UserManagement />
              </PrivateRoute>
            } />
            <Route path="/posts" element={
              <PrivateRoute allowedRoles={['super_admin', 'editor']}>
                <PostManagement />
              </PrivateRoute>
            } />
            <Route path="/lessons/manage" element={
              <PrivateRoute allowedRoles={['super_admin', 'editor']}>
                <LessonManagement />
              </PrivateRoute>
            } />
            <Route path="/lessons" element={
              <PrivateRoute allowedRoles={['super_admin', 'editor', 'allievo']}>
                <LessonList />
              </PrivateRoute>
            } />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;