import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const userRole = localStorage.getItem('userRole');

  return (
    <div className="dashboard">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <p className="mb-4">Benvenuto nell'area riservata di AESLIS. Il tuo ruolo è: {userRole}</p>
      
      {userRole === 'super_admin' && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Azioni disponibili:</h2>
          <ul className="list-disc pl-5">
            <li><Link to="/users" className="text-blue-600 hover:underline">Gestione Utenti</Link></li>
            <li><Link to="/posts" className="text-blue-600 hover:underline">Gestione Post</Link></li>
            <li><Link to="/lessons/manage" className="text-blue-600 hover:underline">Gestione Lezioni</Link></li>
          </ul>
        </div>
      )}

      {userRole === 'editor' && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Azioni disponibili:</h2>
          <ul className="list-disc pl-5">
            <li><Link to="/posts" className="text-blue-600 hover:underline">Gestione Post</Link></li>
            <li><Link to="/lessons/manage" className="text-blue-600 hover:underline">Gestione Lezioni</Link></li>
          </ul>
        </div>
      )}

      {userRole === 'allievo' && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Azioni disponibili:</h2>
          <ul className="list-disc pl-5">
            <li><Link to="/lessons" className="text-blue-600 hover:underline">Visualizza Lezioni</Link></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dashboard;