import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LessonList = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get('/api/lessons');
        setLessons(response.data);
      } catch (error) {
        console.error('Errore nel caricamento delle lezioni:', error);
      }
    };
    fetchLessons();
  }, []);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Lezioni Disponibili</h2>
      <ul>
        {lessons.map(lesson => (
          <li key={lesson.id}>{lesson.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default LessonList;