import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  return (
    <header className="bg-aeslis-blue text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">AESLIS - Area Riservata</h1>
        <nav>
          <Link to="/dashboard" className="mr-4">Dashboard</Link>
          {userRole === 'super_admin' && (
            <>
              <Link to="/users" className="mr-4">Gestione Utenti</Link>
              <Link to="/posts" className="mr-4">Gestione Post</Link>
              <Link to="/lessons/manage" className="mr-4">Gestione Lezioni</Link>
            </>
          )}
          {userRole === 'editor' && (
            <>
              <Link to="/posts" className="mr-4">Gestione Post</Link>
              <Link to="/lessons/manage" className="mr-4">Gestione Lezioni</Link>
            </>
          )}
          {userRole === 'allievo' && (
            <Link to="/lessons" className="mr-4">Lezioni</Link>
          )}
          <button onClick={handleLogout} className="bg-red-500 px-4 py-2 rounded">Logout</button>
        </nav>
      </div>
    </header>
  );
};

export default Header;