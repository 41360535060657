import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/api/users');
        console.log('Risposta API completa:', response);
        console.log('Tipo di response.data:', typeof response.data);
        console.log('Contenuto di response.data:', response.data);

        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else if (typeof response.data === 'object' && response.data !== null) {
          // Se la risposta è un oggetto, cerchiamo di estrarre un array
          const usersArray = Object.values(response.data).find(Array.isArray);
          if (usersArray) {
            setUsers(usersArray);
          } else {
            throw new Error('Impossibile trovare un array di utenti nella risposta');
          }
        } else {
          throw new Error('I dati ricevuti non sono nel formato atteso');
        }
      } catch (error) {
        console.error('Errore nel caricamento degli utenti:', error);
        setError(error.message || 'Si è verificato un errore nel caricamento degli utenti');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (isLoading) {
    return <div>Caricamento utenti in corso...</div>;
  }

  if (error) {
    return <div>Errore: {error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Gestione Utenti</h2>
      {users.length > 0 ? (
        <ul>
          {users.map(user => (
            <li key={user.id}>{user.email} - {user.role}</li>
          ))}
        </ul>
      ) : (
        <p>Nessun utente trovato.</p>
      )}
    </div>
  );
};

export default UserManagement;